document.addEventListener("turbolinks:load", function () {
  $(function() {
  $('.header__right__box').on('click', function() {
      $(this).toggleClass('active');

      if($(this).hasClass('active')) {
          $('.hamburger__main').addClass("active");
          $('.hamburger__back').fadeIn();
      }else{
        $('.hamburger__main').removeClass("active");
        $('.hamburger__back').fadeOut();



      }

      

  });

});
  //   function checkBreakPoint() {
  // w = $(window).width();
  // if (w <= 560) {
  //   $('li#shopinmap').css()
  // } else {
  //   // PC向け
  //   $('li#shopinmap').css('display','none');
  // }
  // }
  // // ウインドウがリサイズする度にチェック
  // $(window).resize(function(){
  // checkBreakPoint();
  // });
  // checkBreakPoint();


  $(function() {
    $('.hamburger__main__center__area__content__left__menu a, .hamburger__back').on('click', function() {
      $('.hamburger__main').removeClass('active');
      $('.header__right__box').toggleClass('active');
      $('.hamburger__back').fadeOut();

    });
  });

}, {passive: true});


// 管理画面のハンバーガー
document.addEventListener("turbolinks:load", function () {
  $(function() {
    $('.headerbar__left__menu__box').click(function() {
        $(this).toggleClass('active');
  
        if ($(this).hasClass('active')) {
            $('.mypagemenu__main__sidebar, .mypage__items__main__sidebar, .menusshow__sidebar, .mypagelogout__main__sidebar, .users__edit__sidebar ').show();
            
        } else {
            $('.mypagemenu__main__sidebar, .mypage__items__main__sidebar, .menusshow__sidebar, .mypagelogout__main__sidebar, .users__edit__sidebar ').hide();
  
        }
    });
  });
    //   function checkBreakPoint() {
    // w = $(window).width();
    // if (w <= 560) {
    //   $('li#shopinmap').css()
    // } else {
    //   // PC向け
    //   $('li#shopinmap').css('display','none');
    // }
    // }
    // // ウインドウがリサイズする度にチェック
    // $(window).resize(function(){
    // checkBreakPoint();
    // });
    // checkBreakPoint();
  
  
    $(function() {
      $(".mypage__sidebar a ul").click(function() {
        $('.mypagemenu__main__sidebar, .mypage__items__main__sidebar, .menusshow__sidebar, .mypagelogout__main__sidebar, .users__edit__sidebar ').hide();
        $('.headerbar__left__menu__box').toggleClass('active');
      });
    });
  
  }, {passive: true});
  