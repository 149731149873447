document.addEventListener("turbolinks:load", function () {

  // $(window).on('scroll', function () {
    //facebook-jsonを取得して表示


    
//         var count = 0;
//         var limit = 14; //表示件数
//         var text = '';
//         var data;
//         var graph_api = 'https://graph.facebook.com/v13.0/';
//         var accessToken = 'EAAalZBFX4NJMBACpO1m0znQZBYZChfuh5yuNKUzjrPsKhZCPJxit2iyzIc9uSrKK1a9D8aeDNxBqNslTO42t4ofWMIzHamnlSC4ZAzDWx2TNes5L5aO73WNSaGzwFaWatiYP0XjxDy6lh4CRDqVFFTclFWZBvpOvlNwuLKBRTUxZBcw8YmV63lo'; // アクセストークン
//         var businessID = '17841453257416833'; //グラフAPIエクスプローラで取得したinstagram_business_accountのID
//         var fields = 'media{caption,media_url,thumbnail_url,permalink,timestamp,username}';
//         var url = graph_api + businessID + '?fields=' + fields + "&access_token=" + accessToken;
//         $.ajax({
//             url: url
//         })
//         .done(function(res) {
//             data = res.media;
//             limit = 14;
//             count = 0;
//             $.each(data, function(index, val) {
//                 $.each(val, function(i, elem) {
//                     if (elem.media_url && count < limit) {
//                         text1 = '<li><a href="'+elem.permalink+'" target="_blank">';
//                         text2 = '<img src="'+ elem.media_url +'">'; 
//                         text3 = '</a></li>';
//                         count ++;
//                         text = text + text1 + text2 + text3;
//                     }
//                 });
//             });
//             $('#instagram-list').html(text);
//         })
//         .fail(function(jqXHR, status) {
//             $('#instagram-list').html('<li>読み込みに失敗しました。</li>');
//         })
    
//   });





// $(function(){
//     let list = '';
//     const limit = "14"; //表示件数
//     const accessToken = "EAAalZBFX4NJMBACpO1m0znQZBYZChfuh5yuNKUzjrPsKhZCPJxit2iyzIc9uSrKK1a9D8aeDNxBqNslTO42t4ofWMIzHamnlSC4ZAzDWx2TNes5L5aO73WNSaGzwFaWatiYP0XjxDy6lh4CRDqVFFTclFWZBvpOvlNwuLKBRTUxZBcw8YmV63lo"; // アクセストークン
//     const businessID = "17841453257416833"; //instagram_business_accountのID
//     const url = `https://graph.facebook.com/v13.0/${businessID}?fields=name,media.limit(${limit}){caption,media_url,thumbnail_url,permalink,like_count,comments_count,media_type}&access_token=${accessToken}`;
//     $.ajax({
//       url: url
//     }).done((res)=> {
//       const data = res.media;
//       $.each(data, function(index, val) {
//         $.each(val, function(i, item) {
//           console.log(item);
//           if(item.media_url){
//             //メディアのタイプがビデオの場合、サムネを取得
//             media = (item.media_type == 'VIDEO' ? item.thumbnail_url : item.media_url);
  
//             // 一覧を変数listに格納
//             list +=
//             `<li>
//               <a href="${item.permalink}" target="_blank" rel="noopener">
//               <img src="${media}">
//               <span class="like"><i class="fa fa-heart"></i>${item.like_count}</span></a>
//             </li>`;
//           }
  
//         })
//       });
//     $('#instagram-list').html(list);
//     }).fail(function(jqXHR, status) {
//       $('#instagram-list').html('<p>読み込みに失敗しました。</p>');
//     });
//   });














          var count = 0;
        var limit = 14; //表示件数
        var text = '';
        var data;
        var graph_api = 'https://graph.facebook.com/v13.0/';
        var accessToken = 'EAAalZBFX4NJMBACpO1m0znQZBYZChfuh5yuNKUzjrPsKhZCPJxit2iyzIc9uSrKK1a9D8aeDNxBqNslTO42t4ofWMIzHamnlSC4ZAzDWx2TNes5L5aO73WNSaGzwFaWatiYP0XjxDy6lh4CRDqVFFTclFWZBvpOvlNwuLKBRTUxZBcw8YmV63lo'; // アクセストークン
        var businessID = '17841453257416833'; //グラフAPIエクスプローラで取得したinstagram_business_accountのID
        var fields = 'media{caption,media_url,thumbnail_url,permalink,like_count,comments_count,media_type}';
        var url = graph_api + businessID + '?fields=' + fields + "&access_token=" + accessToken;
        $.ajax({
            url: url
        })
        .done(function(res) {
            data = res.media;
            limit = 14;
            count = 0;
            $.each(data, function(index, val) {
                $.each(val, function(i, item) {
                    if (item.media_url && count < limit) {
                        media = (item.media_type == 'VIDEO' ? item.thumbnail_url : item.media_url);

                        text1 = '<li><a href="'+item.permalink+'" target="_blank">';
                        text2 = '<img src="'+ media +'">'; 
                        text3 = '</a></li>';
                        count ++;
                        text = text + text1 + text2 + text3;
                    }
                });
            });
            $('#instagram-list').html(text);
        })
        .fail(function(jqXHR, status) {
            $('#instagram-list').html('<li>読み込みに失敗しました。</li>');
        })
    
        



});
