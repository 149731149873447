// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "../stylesheets/header"
import "../stylesheets/pic"
import "../stylesheets/reset"
import "../stylesheets/application"
import "../stylesheets/main"
import "../stylesheets/pickup"
import "../stylesheets/newpic"
import "../stylesheets/tag"
import "../stylesheets/insta"
import "../stylesheets/footer"
import "../stylesheets/pics_show"
import "../stylesheets/picform"
import "../stylesheets/category_main"
import "../stylesheets/categories_index"
import "../stylesheets/categories_show"
import "../stylesheets/categories_pickup"
import "../stylesheets/tagpick"
import "../stylesheets/hamburger"
import "../stylesheets/mypage_header"
import "../stylesheets/about_main"
import "../stylesheets/terms_main"
import "../stylesheets/inquiry_index"
import "../stylesheets/inquiry_confilm"
import "../stylesheets/inquiry_thanks"
import "../stylesheets/request_index"
import "../stylesheets/login"
import "../stylesheets/users_edit"
import "../stylesheets/allpic_button"
import "../stylesheets/kaminari"
import "../stylesheets/mypage"
import "../stylesheets/mypage_side"
import "../stylesheets/mypage_category"
import "../stylesheets/mypage_tags"
import "../stylesheets/achievement"
import "../stylesheets/achpickup"
import "../stylesheets/mypage_achievement"

import "../javascripts/insta"
import "../javascripts/imageform"
import "../javascripts/download"
import "../javascripts/category_pic"
import "../javascripts/humberger"


const images = require.context("../images", true);
const imagePath = name => images(name, true);

Rails.start()
Turbolinks.start()
ActiveStorage.start()
require('jquery')


