document.addEventListener("turbolinks:load", function () {

$(function() {
  $('a.pics__download').on('click', function(e){
    var href_url = $(this).attr('href');
    var href_name = $(this).attr('href').replace(/\\/g,'/').replace( /.*\//, '' );
    $(e.target).attr({
      download: href_name,
      href: href_url
    });
  });
});
});
