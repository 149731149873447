document.addEventListener("turbolinks:load", function () {
  $(function(){
  $(".pic__box__image__form__field").click(function () {
    $("#pic_image").click();
    return false; // must!
  });
  
  
    //画像ファイルプレビュー表示のイベント追加 fileを選択時に発火するイベントを登録
    $('#pic_image').on('change', function(e) {
     
      var file = e.target.files[0],
          reader = new FileReader(),
          $preview = $(".pic-image");
          t = this;
  
      // 画像ファイル以外の場合は何もしない
      if(file.type.indexOf("image") < 0){
        return false;
      }
  
      // ファイル読み込みが完了した際のイベント登録
      reader.onload = (function(file) {
        return function(e) {
          //既存のプレビューを削除
          $preview.empty();
          // .prevewの領域の中にロードした画像を表示するimageタグを追加
          $preview.attr("src", e.target.result)
          $(".pic__box__image__form__field__text").empty();
        };
      })(file);
  
      reader.readAsDataURL(file);
    });
  
  });
  });
  

  document.addEventListener("turbolinks:load", function () {
    $(function(){
    $(".pic__box__image__form__field").click(function () {
      $("#achievement_image").click();
      return false; // must!
    });
    
    
      //画像ファイルプレビュー表示のイベント追加 fileを選択時に発火するイベントを登録
      $('#achievement_image').on('change', function(e) {
       
        var file = e.target.files[0],
            reader = new FileReader(),
            $preview = $(".pic-image");
            t = this;
    
        // 画像ファイル以外の場合は何もしない
        if(file.type.indexOf("image") < 0){
          return false;
        }
    
        // ファイル読み込みが完了した際のイベント登録
        reader.onload = (function(file) {
          return function(e) {
            //既存のプレビューを削除
            $preview.empty();
            // .prevewの領域の中にロードした画像を表示するimageタグを追加
            $preview.attr("src", e.target.result)
            $(".pic__box__image__form__field__text").empty();
          };
        })(file);
    
        reader.readAsDataURL(file);
      });
    
    });
    });
  
  
  
  
  
  
  
  
  
  
  
  